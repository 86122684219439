import React from "react";
import "./Header.scss";
import logo from "../../assets/images/onboardingclublogo.svg";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <div className="header_inner">
        <Link to="/" className="header_inner_left">
          <img src={logo} alt="onbooarding logo" />
        </Link>
        <div className="header_inner_right">
          <Link to="/contact" className="header_inner_right_text">
            Contact Us
          </Link>
          <Link
            to="https://app.onboarding.club/"
            className="header_inner_right_button hidden md:flex"
          >
            Launch App
          </Link>
        </div>
      </div>
    </div>
  );
}
