import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ChainPage from "./pages/ChainPage/ChainPage";
import ReferralRedirect from "./pages/Referral/ReferralRedirect";
import MainRouter from "./routers/index"
import ResourceHubPage from "./pages/ResourceHubPage/ResourceHubPage";
import GigsPage from "./pages/GigsPage/GigsPage";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import CreateGigPage from "./pages/CreateGigPage/CreateGigPage";
import Ecosystems from "./pages/EcosystemPage/Ecosystems";
import ContactUs from "./pages/ContactUs/ContactUs";
import Login from "./pages/Login/Login";
import UserProfileSignup from "./pages/UserProfileSignup/UserProfileSignup";
import UserProfile from "./pages/UserProfile/UserProfile";
import RegisterOrg from "./pages/RegisterOrg/RegisterOrg";
import Auth from "./pages/Auth/authPage";
import { useEffect } from "react";
import OBCApi from "./api/api";
import resources from './pages/ResourceHubPage/resources.json';
import Overview from "./pages/ResourceHubPage/Overview";
import Tutorial from './pages/ResourceHubPage/Tutorial';
import Callback from "./pages/Auth/Callback";
import { useDispatch } from 'react-redux'
import { setUser } from "./store/userSlice";
import AppRouter from "./routers/AppRouter";
import getCookieByName from "./utils/getCookie";

function App() {
  const miniCourses = resources.stellar.mini_courses;

  const dispatch = useDispatch()


  useEffect(() => {
    const initialCall = async () => {
      if (getCookieByName("token") !== null) {
        const api = new OBCApi(localStorage.getItem("jwt"), "");
        await api
          .userGetUserProfile()
          .then((res) => {
            dispatch(setUser(res.data.data))
            localStorage.setItem("user", JSON.stringify(res.data.data));
          })
          .catch((err) => {
            localStorage.clear();
          });
      }
    }

    initialCall();

  }, []);

  return (
    <MainRouter />
  );
}

export default App;
