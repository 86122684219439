import React, { useEffect, useState } from "react";
import "./UserProfileSignup.scss";
import linked_in from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import Header from "../../components/Header/StellarHeader/Header";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/userSlice";
import { useDispatch } from "react-redux";

export default function UserProfileSignup() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    bio: "",
    tags: [],
    twitterId: "",
    telegramId: "",
    linkedinId: "",
    discordId: "",
    skills: []
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTagsInputChange = (val) => {
    setFormData(
      {
        ...formData,
        skills: val
      }
    )
  }

  const dispatch = useDispatch()

  const handleSubmit = () => {
    setShowLoading(true);
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    api
      .userUpdateUser(formData)
      .then((res) => {
        setShowLoading(false);
        setIsSubmitted(true);
        dispatch(setUser(res.data.data.user))
        navigate(`/profile/${res.data.data.user.username}`);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };

  useEffect(() => {
    const api = new OBCApi(localStorage.getItem("jwt"), "")
    api.userGetUserProfile().then((res) => {
      setFormData(res.data.data)
    }).catch((err) => {
      navigate("/ecosystem")
    })
  }, [])

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="profilesu"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="profilesu_main">
        <div className="profilesu_main_profilesu">
          Let's #build your profile
        </div>
        <div className="profilesu_main_title">Onboarding.club</div>
        <div className="profilesu_main_subtitle">
          Completing your profile helps get better opportunities
        </div>
        <div className="profilesu_main_container">
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Name</div>
            <input
              className="profilesu_main_container_input"
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Username</div>
            <input
              className="profilesu_main_container_input"
              type="text"
              name="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Bio</div>
            <textarea
              rows="3"
              className="profilesu_main_container_input"
              name="bio"
              placeholder="Tell us about yourself..."
              value={formData.bio}
              onChange={handleInputChange}
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Skillset</div>
            <EditableMultiInput
              property="skills"
              value={formData.skills}
              setValue={handleTagsInputChange}
              placeholder="Enter or select your skills and press enter"
            />
          </div>
          <div className="profilesu_main_container_inputcontainer">
            <div className="profilesu_main_container_title">Social Links</div>
            <div className="profilesu_main_container_socials">
              <div className="profilesu_main_container_socials_row">
                <div className="profilesu_main_container_socials_row_input">
                  <img src={twitter} alt="twitter" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    twitter.com/
                  </div>
                  <input
                    type="text"
                    name="twitterId"
                    value={formData.twitterId}
                    onChange={handleInputChange}
                    className="mt-1 ml-[1px]"
                  />
                </div>
                <div className="profilesu_main_container_socials_row_input">
                  <img src={telegram} alt="telegram" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    t.me/
                  </div>
                  <input
                    type="text"
                    name="telegramId"
                    value={formData.telegramId}
                    onChange={handleInputChange}
                    className="mt-1 ml-[1px]"
                  />
                </div>
              </div>
              <div className="profilesu_main_container_socials_row">
                <div className="profilesu_main_container_socials_row_input">
                  <img src={linked_in} alt="linked_in" className="mr-2" />
                  <div className="profilesu_main_container_socials_row_input_text">
                    linked_in.com/in/
                  </div>
                  <input
                    type="text"
                    name="linkedinId"
                    id="linkedinId"
                    value={formData.linkedinId}
                    onChange={handleInputChange}
                    className="mt-1 ml-[1px]"
                  />
                </div>
                <div className="profilesu_main_container_socials_row_input">
                  <img src={discord} alt="twitter" className="mr-2" />
                  <input
                    type="text"
                    name="discordId"
                    placeholder="Enter your Discord ID"
                    value={formData.discordId}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={handleSubmit}
            className="profilesu_main_container_submit"
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}
