import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: "",
  email: "",
  isAdmin: false,
  bio: "",
  discordId: "",
  id: "",
  linkedinId: "",
  profilePhoto: "",
  projects: [],
  skills: [],
  referralCode: "",
  username: "",
  walletPublicAddress: "",
  walletPrivateAddress: "",
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name
      state.email = action.payload.email
      state.isAdmin = action.payload.isAdmin
      state.bio = action.payload.bio
      state.discordId = action.payload.discordId
      state.id = action.payload.id
      state.linkedinId = action.payload.linkedinId
      state.profilePhoto = action.payload.profilePhoto
      state.projects = action.payload.projects
      state.skills = action.payload.skills
      state.username = action.payload.username
      state.referralCode = action.payload.referralCode
      state.walletPublicAddress = action.payload.walletPublicAddress
      state.walletPrivateAddress = action.payload.walletPrivateAddress
    }
  },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
