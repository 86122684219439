import React, { useState } from "react";
import "./Modal.scss";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import OBCApi from "../../api/api";

export default function AddProjectModal({ onClose, initialValues, setLoading }) {
  const [formData, setFormData] = useState({
    project_name: "",
    project_url: "",
    project_description: "",
    technologies_used: [],
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSetValue = (val) => {
    setFormData({
      ...formData,
      technologies_used: val,
    });

  };

  const handleSubmit = () => {
    setLoading(true)
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    if (!initialValues.projects) {
      initialValues.projects = []
      console.log("project added?")
    }
    initialValues.projects?.push(formData);
    api
      .userUpdateUser(initialValues)
      .then((res) => {
        setIsSubmitted(true);
        onClose();
        setLoading(false)
      })
      .catch((err) => {
        //TODO: handle error
        setIsSubmitted(true);
        onClose();
        setLoading(false)
      });
  };

  return (
    <div className="profilemodal">
      <div className="profilemodal_content">
        <div className="profilemodal_content_header">
          <div className="profilemodal_content_header_title">Add Project</div>
          <div onClick={onClose} className="profilemodal_content_header_cross">
            X
          </div>
        </div>
        <div className="profilemodal_content_main">
          <div className="profilemodal_content_main_container">
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Project Name
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="project_name"
                placeholder="Enter project name"
                value={formData.project_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Project URL
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="project_url"
                placeholder="Enter project URL"
                value={formData.project_url}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Project Description
              </div>
              <textarea
                rows="7"
                className="profilemodal_content_main_container_input"
                name="project_description"
                placeholder="Enter project description"
                value={formData.project_description}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Technologies Used
              </div>
              <EditableMultiInput
                property="technologies_used"
                value={formData.technologies_used}
                setValue={handleSetValue}
                placeholder="Enter or select technologies used and press enter"
              />
            </div>
          </div>
        </div>
        <div className="profilemodal_content_footer">
          <div
            onClick={handleSubmit}
            className="profilemodal_content_footer_button"
          >
            Add Project
          </div>
        </div>
      </div>
    </div>
  );
}
