import React, { useState } from "react";
import "./Login.scss";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { Link } from "react-router-dom";
import Header from "../../components/Header/StellarHeader/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import { backendUrl } from "../../api/configs";
import { useParams } from "react-router-dom";
import OBCApi from "../../api/api";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";
import { Input } from "@mantine/core";
import { ArrowRight, ArrowRightAlt, Tag } from "@mui/icons-material";

const getCookie = (name) => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }

  return '';
};

const removeCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};


export default function Login() {
  const { chain } = useParams();
  const referralCode = localStorage.getItem("referralCode")
  const referralUser = JSON.parse(localStorage.getItem("referralUser"))
  const [refreshPage, setRefreshPage] = useState(false)
  const [formData, setFormData] = useState({
    blockchainFoundation: "",
    organizationEmail: "",
    onboardingPurpose: "",
  });
  const [referralCodeValue, setReferralCodeValue] = useState("")
  const [showReferralInput, setShowReferralInput] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const dispatch = useDispatch()

  const handleGoogleLogin = () => {
    //TODO: change to subdomain
    const authWindow = window.open(
      `${backendUrl}/user/auth/google?redirect=https://app.onboarding.club/auth`,
      "_blank",
      "width=500,height=800"
    );
    setShowLoading(true)
    // check if the window was opened and then closed
    const interval = setInterval(async () => {
      if (authWindow.closed) {
        try {
          clearInterval(interval);
          const jwt = getCookie("token")
          if (jwt) {
            removeCookie("token");

            const api = new OBCApi(localStorage.getItem("jwt"), "")

            const referalCode = localStorage.getItem("referralCode")

            if (referralCode) {
              try {
                const applyReferral = await api.userApplyReferral(referalCode)
                localStorage.removeItem("referralUser")
                localStorage.removeItem("referralCode")
              } catch (e) {
                showErrorSnack(e.response.data?.message || "Something went wrong")
                localStorage.removeItem("referralUser")
                localStorage.removeItem("referralCode")
              }
            }

            const profile = await api.userGetUserProfile()

            dispatch(setUser(profile.data?.data))

            if (profile.data?.data?.bio !== null && profile.data?.data?.bio !== "") {
              navigate("/profile/" + profile.data?.data?.username);
              return;
            }

            navigate("/profile-complete");
          }
        }
        catch (e) {
          setShowLoading(false)
        }

      }
    }, 1000);

    if (!authWindow) {
      showErrorSnack("Please allow popups for this site");
      return;
    }
  };

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="login"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="login_main">
        {
          referralUser && (
            <div className="flex space-x-2 items-center px-3 py-2 rounded-full bg-zinc-900 mb-6">
              <img src={referralUser?.profilePhoto} className="w-10 h-10 rounded-full border-2 border-[#8378BA]" />
              <p>Referred by <b>{referralUser?.username}</b></p>
            </div>
          )
        }
        <div className="login_main_login">Get Started With</div>
        <div className="login_main_title">Onboarding.club</div>
        <div className="login_main_subtitle">
          Sign in/Register to dive deep into the world of blockchains
        </div>
        <div className="login_main_container">
          <div
            onClick={handleGoogleLogin}
            className="login_main_container_googlebutton"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_164_1367)">
                <path
                  d="M19.8042 10.2302C19.8042 9.55044 19.7491 8.86699 19.6315 8.19824H10.1992V12.0491H15.6007C15.3765 13.291 14.6563 14.3897 13.6018 15.0878V17.5864H16.8243C18.7166 15.8448 19.8042 13.2726 19.8042 10.2302Z"
                  fill="#4285F4"
                />
                <path
                  d="M10.1999 20.0008C12.897 20.0008 15.1714 19.1152 16.8286 17.5867L13.6061 15.088C12.7096 15.698 11.5521 16.0434 10.2036 16.0434C7.59474 16.0434 5.38272 14.2833 4.58904 11.917H1.26367V14.4928C2.96127 17.8696 6.41892 20.0008 10.1999 20.0008Z"
                  fill="#34A853"
                />
                <path
                  d="M4.58467 11.9168C4.16578 10.6748 4.16578 9.32995 4.58467 8.08799V5.51221H1.26297C-0.155365 8.33785 -0.155365 11.6669 1.26297 14.4925L4.58467 11.9168Z"
                  fill="#FBBC04"
                />
                <path
                  d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96126 2.13185 1.26367 5.51234L4.58537 8.08813C5.37537 5.71811 7.59106 3.95805 10.1999 3.95805Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_164_1367">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="login_main_container_googlebutton_text">
              Continue with Google
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col space-y-2 items-center">
          <p className="underline underline-offset-2" onClick={() => {
            setShowReferralInput(true)
          }}>
            {
              localStorage.getItem("referralCode") ? "Use another referral code instead? " : "Have a referral code? "
            }
            <span className="underline underline-offset-2 cursor-pointer">Click here</span>
          </p>
          {showReferralInput && (
            <form
              onSubmit={(e) => {
                e.preventDefault()
                navigate('/refer/' + referralCodeValue)
              }}
              className="flex items-center space-x-2">
              <Input
                styles={{
                  input: {
                    backgroundColor: "#111111",
                    fontSize: "16px",
                  },
                }}
                placeholder="Referral Code"
                className="w-44"
                value={referralCodeValue}
                onChange={(e) => {
                  setReferralCodeValue(e.target.value)
                }}
                leftSection={<Tag className="w-10" />}
              />
              <button
                onClick={() => {
                  navigate('/refer/' + referralCodeValue)
                }}
                type="submit"
                className="h-6 bg-green-600 text-white rounded-full w-6 flex items-center cursor-pointer">
                <ArrowRight />
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
