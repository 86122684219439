import React, { useEffect, useState } from "react";
import "./Modal.scss";
import linkedin from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import { Link } from "react-router-dom";
import EditableMultiInput from "../CreateGigPage/EditableMultiInput";
import OBCApi from "../../api/api";
import { TagsInput } from "@mantine/core";

export default function EditProfileModal({ onClose, initialData }) {
  const [formData, setFormData] = useState({
    name: initialData?.name || "",
    username: initialData?.username || "",
    bio: initialData?.bio || "",
    skills: initialData?.skills || [],
    twitterId: initialData?.twitterId || "",
    telegramId: initialData?.telegramId || "",
    linkedinId: initialData?.linkedinId || "",
    discordId: initialData?.discordId || "",
  });

  console.log(formData)
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTagsInputChange = (val) => {
    setFormData(
      {
        ...formData,
        skills: val
      }
    )
  }

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };

  const handleSubmit = async () => {
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    await api
      .userUpdateUser(formData)
      .then((res) => {
        setIsSubmitted(true);
        onClose()
      })
      .catch((err) => {
        //TODO: Handler Error
        setIsSubmitted(true);
      });
  };
  return (
    <div className="profilemodal">
      <div className="profilemodal_content">
        <div className="profilemodal_content_header">
          <div className="profilemodal_content_header_title">Profile</div>
          <div onClick={onClose} className="profilemodal_content_header_cross">
            X
          </div>
        </div>
        <div className="profilemodal_content_main">
          <div className="profilemodal_content_main_container">
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Name
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData?.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Username
              </div>
              <input
                className="profilemodal_content_main_container_input"
                type="text"
                name="username"
                placeholder="Enter your username"
                value={formData?.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Bio
              </div>
              <textarea
                rows="7"
                className="profilemodal_content_main_container_input"
                name="bio"
                placeholder="Tell us about yourself..."
                value={formData?.bio}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Skillset
              </div>
              <EditableMultiInput
                value={formData?.skills}
                setValue={handleTagsInputChange}
                placeholder="Enter or select your skills and press enter"
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Social Links
              </div>
              <div className="profilemodal_content_main_container_socials">
                <div className="profilemodal_content_main_container_socials_row">
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={twitter} alt="twitter" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      twitter.com/
                    </div>
                    <input
                      type="text"
                      name="twitterId"
                      value={formData?.twitterId}
                      onChange={handleInputChange}
                      className="mt-1 ml-[1px]"
                    />
                  </div>
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={telegram} alt="twitter" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      t.me/
                    </div>
                    <input
                      type="text"
                      name="telegramId"
                      value={formData?.telegramId}
                      onChange={handleInputChange}
                      className="mt-1 ml-[1px]"
                    />
                  </div>
                </div>
                <div className="profilemodal_content_main_container_socials_row">
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={linkedin} alt="linkedin" className="mr-2" />
                    <div className="profilemodal_content_main_container_socials_row_input_text">
                      linkedin.com/in/
                    </div>
                    <input
                      type="text"
                      name="linkedinId"
                      id="linkedinId"
                      value={formData?.linkedinId}
                      onChange={handleInputChange}
                      className="mt-1 ml-[1px]"
                    />
                  </div>
                  <div className="profilemodal_content_main_container_socials_row_input">
                    <img src={discord} alt="twitter" className="mr-2" />
                    <input
                      type="text"
                      name="discordId"
                      placeholder="Enter your Discord ID"
                      value={formData?.discordId}
                      className="mt-1"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profilemodal_content_footer">
          <div
            onClick={handleSubmit}
            className="profilemodal_content_footer_button"
          >
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
}
