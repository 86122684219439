import axios from "axios";
import { backendUrl } from "./configs";

class OBCApi {
  jwt;
  base = `${backendUrl}`;
  chain;
  constructor(jwt, chain) {
    this.jwt = jwt;
    this.chain = chain;
  }

  //User const routes

  ////GET
  userGetUser(username) {
    const route = `/user/profile/${username}`;
    return this.prepGetRequest(route, {});
  }
  userGetUserProfile() {
    const route = `/user/profile`;
    return this.prepGetRequest(route, {});
  }
  userGetUserProfileUnauthenticated(referralCode) {
    const route = `/user/profile/referred/${referralCode}`
    return this.prepGetRequest(route, {})
  }
  userLogOut() {
    const route = `/user/auth/logout`
    return this.prepGetRequest(route, {})
  }
  ////PUT
  userUpdateUser(userEntity) {
    const route = `/user/profile`;
    return this.prepPutRequest(route, userEntity);
  }

  // Generate stellar wallet
  userGenerateStellarWallet() {
    const route = `/user/profile/wallet/generate`;
    return this.prepGetRequest(route, {});
  }

  // Apply User Referral
  userApplyReferral(referralCode) {
    const route = `/user/profile/referral/${referralCode}`;
    return this.prepGetRequest(route, {});
  }

  // Get User Referrals
  userGetMyReferrals() {
    const route = `/user/profile/referrals/myreferrals`;
    return this.prepGetRequest(route, {});
  }

  //Org const routes

  ////POST
  orgCreateOrg(orgEntity) {
    const route = `/user/organisation/`;
    return this.prepPostRequestFormData(route, orgEntity);
  }

  ////GET
  orgGetOrg(username) {
    const route = `/user/organisation/${username}`;
    return this.prepGetRequest(route, {});
  }

  orgCheckUsername(username) {
    const route = `/user/organisation/check/${username}`;
    return this.prepGetRequest(route, {});
  }

  ////PUT
  orgUpdateOrg(orgEntity) {
    const route = `/${this.chain}/organisation`;
    return this.prepPutRequest(route, orgEntity);
  }

  //Gigs const routes

  ////POST
  gigCreateGig(gigEntity) {
    const route = `/admin/${this.chain}/gigs`;
    return this.prepPostRequest(route, gigEntity);
  }
  gigDeleteGig(organisation) {
    const route = `/user/${this.chain}/${organisation}/gigs/delete`;
    return this.prepPostRequest(route, {});
  }

  ////GET
  gigGetOrgGigs(organisation) {
    const route = `/user/${this.chain}/gigs/organisation/${organisation}`;
    return this.prepGetRequest(route, {});
  }
  gigGetGig(id, organisation) {
    const route = `/user/${this.chain}/gigs/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigGetChainGig() {
    const route = `/user/${this.chain}/gigs/`;
    return this.prepGetRequest(route, {});
  }
  gigGetGigSubmissions(id) {
    const route = `/admin/${this.chain}/gigs/submissions/${id}`;
    return this.prepGetRequest(route, {});
  }

  ////PUT
  gigUpdateGig(gigEntity) {
    const route = `/admin/${this.chain}/gigs/${gigEntity.id}`;
    return this.prepPutRequest(route, gigEntity);
  }

  //Gig Applications

  ////POST
  gigAppCreate(gigAppEntity) {
    const route = `/user/${this.chain}/gigs/${gigAppEntity.gigId}`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppUpdateGigApp(gigAppEntity) {
    const route = `/${this.chain}/gigs/update`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppDeleteGigApp(id) {
    const route = `/${this.chain}/gigs/delete/${id}`;
    return this.prepPostRequest(route, {});
  }
  gigAppSelectWinner(gigApplicationWinnerEntity) {
    const route = `/${this.chain}/gigs/application/select`;
    return this.prepPostRequest(route, gigApplicationWinnerEntity);
  }

  ////GET
  gigAppGetGigApp(id) {
    const route = `/${this.chain}/gigs/application/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigAppUserGigApp() {
    const route = `/${this.chain}/gigs/applications`;
    return this.prepGetRequest(route, {});
  }

  //// Contact US
  contactUs(contactUsEntity) {
    const route = `/user/contact`;
    return this.prepPostRequest(route, contactUsEntity);
  }

  prepPostRequest(route, body) {
    return axios.post(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
      withCredentials: true,
    });
  }

  prepPostRequestFormData(route, body) {
    return axios.post(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.jwt}`,
      },
      withCredentials: true,
    });
  }

  prepGetRequest(route, body) {
    return axios.get(`${this.base}${route}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
      withCredentials: true,
    });
  }

  prepPutRequest(route, body) {
    return axios.put(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
      withCredentials: true,
    });
  }
}

export default OBCApi;
