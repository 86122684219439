import { APP_SUBDOMAIN, STELLAR_SUBDOMAIN } from "../constants/routes";
import AppRouter from "./AppRouter";
import LandingRouter from "./LandingRouter";
import StellarRouter from "./StellarRouter";

const subdomain = window.location.host.split('.')[0];

export default function MainRouter() {
  switch (subdomain) {
    case APP_SUBDOMAIN:
      return <AppRouter />;
    case STELLAR_SUBDOMAIN:
      return <StellarRouter />
    default:
      return <LandingRouter />
  }
}
