import { Route, Routes } from "react-router-dom";
import Callback from "../pages/Auth/Callback";
import HomePage from "../pages/HomePage/HomePage";
import Login from "../pages/Login/Login";
import ReferralRedirect from "../pages/Referral/ReferralRedirect";
import UserProfile from "../pages/UserProfile/UserProfile";
import UserProfileSignup from "../pages/UserProfileSignup/UserProfileSignup";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/authenticate" element={<Login />} />
      <Route path="/profile-complete" element={<UserProfileSignup />} />
      <Route path="/profile/:id" element={<UserProfile />} />
      <Route path="/callback/google" element={<Callback />} />
      <Route path="/refer/:referralCode" element={<ReferralRedirect />} />
    </Routes>
  )
}
