import React, { useEffect } from 'react'
import './HomePage.scss';
import Header from '../../components/Header/Header';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import getCookieByName from '../../utils/getCookie';

export default function HomePage() {
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  useEffect(() => {
    if (!getCookieByName("token")) {
      navigate('/authenticate')
    }
    else {
      if (user?.username) {
        navigate(`/profile/${user?.username}`)
      }
    }
  }, [user])
  return (
    <div className='h-screen w-screen'>
      <Loader showLoading={true} />
    </div>
  )
  //  return (
  //    <div className="homepage">
  //      <Header/>
  //      <div className="homepage_main">
  //        <div className="homepage_main_text">Discover, Connect, Create:</div>
  //        <div className="homepage_main_text">Onboard with Blockchain</div>
  //        <Link to="/ecosystems" className="homepage_main_button">Explore Ecosystems</Link>
  //      </div>
  //    </div>
  //  );
}
