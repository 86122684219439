import { TagsInput } from "@mantine/core";
import React, { useState } from "react";

function EditableMultiInput({
  property,
  value,
  setValue,
  options = ["React JS", "Next JS", "Node JS", "Javascript", "Python", "Solana", "Solidity"],
  placeholder }) {

  return (
    <TagsInput
      name="skills"
      value={value}
      onChange={setValue}
      data={options}
      placeholder={placeholder}
      styles={{
        input: {
          backgroundColor: "#111111",
          padding: "12px 16px",
          fontSize: "16px",
        },
        dropdown: {
          zIndex: 50000
        }
      }}
      className="outline-transparent border-transparent"
      maxDropdownHeight={100}
    />

  );
}

export default EditableMultiInput;
