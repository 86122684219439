import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import OBCApi from "../../api/api"
import Loader from "../../components/Loader/Loader"

export default function ReferralRedirect() {
  const { referralCode } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (referralCode) {
      const api = new OBCApi(localStorage.getItem("jwt"), "")
      localStorage.setItem("referralCode", referralCode)

      api.userGetUserProfileUnauthenticated(referralCode).then((res) => {
        if (res.data.data) {
          localStorage.setItem("referralUser", JSON.stringify(res.data.data.user))
          navigate("/authenticate")
        } else {
          navigate("/")
        }
      })
    }
  }, [referralCode])
  return (
    <Loader showLoading={true} />
  )
}
