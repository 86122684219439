import React, { useEffect, useState } from "react";
import "./UserProfile.scss";
import Header from "../../components/Header/StellarHeader/Header";
import twitterc from "../../assets/images/twittercolored.svg";
import linked_inc from "../../assets/images/linkedincolored.svg";
import telegram_logo from "../../assets/images/telegram_logo.svg"
import discord_logo from "../../assets/images/discord_logo.svg"
import ToggleProfile from "../../components/Toggles/ToggleProfile";
import referralGraphic from "../../assets/images/referralGraphic.png"
import gigImage from "../../assets/images/gigimage.svg";

import { toFormData } from "axios";
import EditProfileModal from "./EditProfileModal";
import AddProjectModal from "./AddProjectModal";
import AddExperienceModal from "./AddExperienceModal";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/userSlice";
import { Button, Input, PasswordInput, Table } from "@mantine/core";
import { Wallet } from "@mui/icons-material";
import getCookieByName from "../../utils/getCookie";


const calculateDaysAgo = (createdDate) => {
  const currentDate = new Date();
  const postedDate = new Date(createdDate);
  const timeDifference = currentDate - postedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};
const renderTagsWithDynamicLimit = (tags, maxCharCount) => {
  let currentCharCount = 0;
  let visibleTags = [];
  let hiddenTagsCount = 0;

  for (const tag of tags) {
    if (currentCharCount + tag.length <= maxCharCount) {
      currentCharCount += tag.length;
      visibleTags?.push(tag);
    } else {
      hiddenTagsCount = tags.length - visibleTags.length;
      break;
    }
  }

  return (
    <>
      {visibleTags?.map((tag, tagIndex) => (
        <span
          key={tagIndex}
          className="userprofile_main_right_cards_card_right_tags_tag"
        >
          {tag}
        </span>
      ))}
      {hiddenTagsCount > 0 && (
        <>
          <span className="userprofile_main_right_cards_card_right_tags_tag">
            +{hiddenTagsCount}
          </span>
        </>
      )}
    </>
  );
};

export default function UserProfile() {
  const navigate = useNavigate()
  const [toggleGigs, setToggleGigs] = useState(true);
  const [walletLoading, setWalletLoading] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false)
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [walletData, setWalletData] = useState({})
  const [error, setError] = useState(null)
  const [cardsData, setCardsData] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const [copyButtonText, setCopyButtonText] = useState("Copy Link")
  const chain = useParams().chain;

  const [referrals, setReferrals] = useState([])

  const id = useParams().id

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()

  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (!getCookieByName("token")) {
      navigate("/authenticate")
    }
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    if (id) {
      setShowLoading(true);
      api
        .userGetUser(id)
        .then((res) => {
          setShowLoading(false);
          setProfileData(res.data.data)
          setShowLoading(false);
          showSuccessSnack("profile fetched!");
        })
        .catch((err) => {
          setShowLoading(false);
          setError(err)
          showErrorSnack(err);
        });
    }
  }, [id])

  //  useEffect(() => {
  //    if (toggleGigs && chain) {
  //      const api = new OBCApi(localStorage.getItem("jwt"), chain);
  //      api
  //        .gigGetChainGig()
  //        .then((res) => {
  //          setShowLoading(false);
  //          setCardsData(res.data.data.gigs)
  //        })
  //        .catch((err) => {
  //          setShowLoading(false);
  //          showErrorSnack(err);
  //        });
  //    }
  //  }, [chain])

  useEffect(() => {
    if (refreshPage) {
      const api = new OBCApi(localStorage.getItem("jwt"), "");
      setShowLoading(true)
      api
        .userGetUserProfile()
        .then((res) => {
          setShowLoading(false);
          setProfileData(res.data.data)
          setRefreshPage(false)
          dispatch(setUser(res.data.data))
        })
        .catch((err) => {
          setShowLoading(false);
          showErrorSnack(err);
          setRefreshPage(false)
        });
    }
  }, [refreshPage])

  useEffect(() => {
    const api = new OBCApi(localStorage.getItem("jwt"), '')
    api.userGetMyReferrals().then((res) => {
      setReferrals(res.data.data.users)
    }).catch((err) => console.log(err))
  }, [])

  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="userprofile"
    >
      <Header />
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="userprofile_main">
        <div className="userprofile_main_left_outer_outer">
          <div className="userprofile_main_left_outer">
            <div className="userprofile_main_left">
              <div className="userprofile_main_left_top">
                <div className="userprofile_main_left_profileimagecontainer">
                  <img className="rounded-full" src={profileData?.profilePhoto} />
                </div>
              </div>
              <div className="userprofile_main_left_namecontainer mx-auto sm:mx-0">
                <div className="userprofile_main_left_namecontainer_name">
                  {profileData?.name}
                </div>{" "}
                <div
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                  className={`userprofile_main_left_namecontainer_editbtn ${user.username !== id && 'hidden'}`}
                >
                  Edit Profile
                </div>{" "}
              </div>
              <div className="userprofile_main_left_username font-bold text-center sm:text-left">
                @{profileData?.username} {profileData?.referredBy && <span className="text-xs text-gray-400 font-light"> | Referred by @<span onClick={() => {
                  navigate(`/profile/${profileData?.referredByUsername}`)
                  setShowLoading(true)
                  setToggleGigs(true)
                }} className="underline underline-offset-2 cursor-pointer">{profileData?.referredByUsername}</span></span>}
              </div>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 mt-3 w-fit mx-auto sm:mx-0">
                {profileData?.twitterId && profileData?.twitterId !== "" &&
                  <div onClick={() => {
                    window.open(`https://twitter.com/${profileData?.twitterId}`, "_blank")
                  }} className="flex space-x-2 p-1 rounded-full bg-[#212121] items-center pr-3 w-fit cursor-pointer">
                    <img src={twitterc} alt="twitter" />
                    <p className="text-white">{profileData?.twitterId}</p>
                  </div>
                }
                {profileData?.linkedinId && profileData?.linkedinId !== "" &&
                  <div onClick={() => {
                    window.open(`https://linkedin.com/in/${profileData?.linkedinId}`, "_blank")
                  }} className="flex space-x-2 p-1 rounded-full bg-[#212121] items-center w-fit pr-3 cursor-pointer">
                    <img src={linked_inc} alt="twitter" />
                    <p className="text-white">{profileData?.linkedinId}</p>
                  </div>
                }
                {profileData?.telegramId && profileData?.telegramId !== "" &&
                  <div onClick={() => {
                    window.open(`https://t.me/${profileData?.telegramId}`, "_blank")
                  }} className="flex space-x-2 p-1 rounded-full bg-[#212121] items-center w-fit pr-3 cursor-pointer">
                    <img src={telegram_logo} alt="twitter" />
                    <p className="text-white">{profileData?.telegramId}</p>
                  </div>
                }
                {profileData?.discordId && profileData?.discordId !== "" &&
                  <div onClick={() => {
                    window.open(`https://discord.com/users/${profileData?.discordId}`, "_blank")
                  }} className="flex space-x-2 p-1 rounded-full bg-[#212121] items-center w-fit pr-3 cursor-pointer">
                    <img src={discord_logo} alt="twitter" />
                    <p className="text-white">{profileData?.discordId}</p>
                  </div>
                }
              </div>
              <div className="userprofile_main_left_infocontainer">
                <div className="userprofile_main_left_infocontainer_title">
                  Skills
                </div>
                <div className="userprofile_main_left_infocontainer_tags">
                  {profileData?.skills?.map((skill, index) => (
                    <div
                      key={index}
                      className="userprofile_main_left_infocontainer_tags_tag"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
              {
                user.walletPublicAddress ? (
                  <div className="flex flex-col sm:flex-row sm:space-x-3 space-y-2 space-x-0 sm:space-y-0 items-center mt-4">
                    <Input.Wrapper label="Stellar Public Key" className="w-full">
                      <Input
                        value={profileData?.walletPublicAddress}
                        placeholder="Public Key"
                      />
                    </Input.Wrapper>
                    {user.username === id && (
                      <PasswordInput className="w-full" label="Stellar Private Key" value={user?.walletPrivateAddress} type={"password"} />
                    )}
                  </div>
                ) : (
                  !walletData?.publicKey ?
                    (
                      <div className="w-20 mt-4">
                        <Button variant={'default'} leftSection={<Wallet />} loading={walletLoading} onClick={async () => {
                          setWalletLoading(true)
                          const api = new OBCApi(localStorage.getItem("jwt"), '')
                          const { data } = await api.userGenerateStellarWallet()
                          setWalletLoading(false)
                          setWalletData(data.data?.keypair)
                        }} >Generate Stellar Wallet</Button>
                      </div>) : (
                      <div className="flex flex-col space-y-2">
                        <div className="flex sm:flex-row flex-col sm:space-x-2 space-x-0 space-y-2 sm:space-y-0 mt-4">
                          <Input.Wrapper label="Stellar Public Key" className="w-full">
                            <Input
                              value={walletData?.publicKey}
                              placeholder="Public Key"
                            />
                          </Input.Wrapper>
                          <PasswordInput label="Stellar Private Key" value={walletData?.privateKey} className="w-full" type={"password"} />
                        </div>
                        <p className="text-xs text-green-500">Make sure to copy and save your private key safely</p>
                      </div>
                    )
                )
              }
            </div>
          </div>
        </div>
        <div className="userprofile_main_right_outer_outer">
          <div className="userprofile_main_right_outer">
            <div className="userprofile_main_right">
              <ToggleProfile
                toggleGigs={toggleGigs}
                setToggleGigs={setToggleGigs}
                username={id}
              />
              {toggleGigs ? (
                <div className="userprofile_main_right_scrollcontainer">
                  <div className="userprofile_main_right_infocontainer">
                    <div className="userprofile_main_right_infocontainer_title">
                      About
                    </div>
                    <div className="userprofile_main_right_infocontainer_value">
                      {profileData?.bio}
                    </div>
                  </div>
                  <div className="userprofile_main_right_workcontainer">
                    <div className="userprofile_main_right_workcontainer_titlecontainer">
                      <div className="userprofile_main_right_workcontainer_titlecontainer_title">
                        Work
                      </div>
                      <div
                        onClick={() => {
                          setOpenProjectModal(true);
                        }}
                        className={`userprofile_main_right_workcontainer_titlecontainer_add flex ${user.username !== id && 'hidden'}`}
                      >
                        +
                      </div>
                    </div>
                    <div className="userprofile_main_right_workcontainer_cards">
                      {profileData?.projects?.map((project, index) => (
                        <div
                          key={index}
                          className="userprofile_main_right_workcontainer_cards_card"
                        >
                          <a
                            href={project.project_url}
                            target="_blank"
                            rel="noreferrer"
                            className="userprofile_main_right_workcontainer_cards_card_title"
                          >
                            {project.project_name}
                          </a>
                          <div className="userprofile_main_right_workcontainer_cards_card_details">
                            <div className="userprofile_main_right_workcontainer_cards_card_details_desc">
                              {project.project_description}
                            </div>
                            <div className="userprofile_main_right_workcontainer_cards_card_details_tags">
                              {project.technologies_used?.map((tech, index) => (
                                <div
                                  key={index}
                                  className="userprofile_main_right_workcontainer_cards_card_details_tags_tag"
                                >
                                  {tech}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="userprofile_main_right_expcontainer">
                    <div className="userprofile_main_right_expcontainer_titlecontainer">
                      <div className="userprofile_main_right_expcontainer_titlecontainer_title">
                        Experience
                      </div>
                      <div
                        onClick={() => {
                          setOpenExperienceModal(true);
                        }}
                        className={`userprofile_main_right_expcontainer_titlecontainer_add flex ${user.username !== id && 'hidden'}`}
                      >
                        +
                      </div>
                    </div>
                    <div className="userprofile_main_right_expcontainer_cards">
                      {profileData?.experiences?.map((exp, index) => (
                        <div
                          key={index}
                          className="userprofile_main_right_expcontainer_cards_card"
                        >
                          <div className="userprofile_main_right_expcontainer_cards_card_top">
                            <div className="userprofile_main_right_expcontainer_cards_card_top_left">
                              <div className="userprofile_main_right_expcontainer_cards_card_title">
                                {exp.designation}
                              </div>
                              <div className="userprofile_main_right_expcontainer_cards_card_subtitle">
                                {exp.company}
                              </div>
                              <div className="userprofile_main_right_expcontainer_cards_card_workdet">
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_1">
                                  {exp.location}
                                </div>
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_2">
                                  <svg
                                    width="3"
                                    height="4"
                                    viewBox="0 0 3 4"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="1.5"
                                      cy="1.75"
                                      r="1.5"
                                      fill="#484848"
                                    />
                                  </svg>
                                </div>
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_3">{`${exp.startDate} - ${exp.working ? "Present" : exp.endDate}`}</div>
                              </div>
                            </div>
                          </div>
                          <div className="userprofile_main_right_expcontainer_cards_card_details">
                            <div className="userprofile_main_right_expcontainer_cards_card_details_desc">
                              {exp.description}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-full h-full relative pb-32 sm:pb-0">
                  <div className="absolute -right-8 -bottom-8">
                    <img src={referralGraphic} className="sm:w-44 w-24" />
                  </div>
                  <div className="flex flex-col space-y-8 text-[#595959] mb-4">
                    <div className="flex flex-col space-y-0 text-zinc-400">
                      <p className="font-bold sm:text-lg text-base">Refer and Earn</p>
                      <p className="font-normal sm:text-base text-xs">Refer Users to Earn Weekly Rewards and get Beta Access to the platform</p>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p className="text-white sm:text-lg text-base font-bold">Share your Referral Code</p>
                      <div className="flex items-center mx-auto sm:mx-0">
                        <div className="bg-zinc-800 py-[8px] pr-2 pl-3 text-white tracking-widest sm:flex hidden text-base rounded-l-full w-fit"><span className="text-zinc-500 font-light">https://app.onboarding.club/refer/</span><b>{user?.referralCode}</b></div>
                        <div className="bg-zinc-800 py-[8px] pr-2 pl-3 text-white tracking-widest flex sm:hidden sm:text-base text-sm rounded-l-full w-fit"><span className="text-zinc-500 font-light"></span><b>{user?.referralCode}</b></div>
                        <button onClick={() => {
                          navigator.clipboard.writeText(`https://app.onboarding.club/refer/${user?.referralCode}`)
                          setCopyButtonText("Copied")
                        }} className={`text-white sm:pl-2 sm:pr-3 pr-2 pl-2 sm:py-[8px] py-[8px] text-sm sm:text-base rounded-r-full outline-none ${copyButtonText === "Copied" ? "bg-green-600" : "bg-zinc-700"}`}>
                          {
                            copyButtonText
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 mt-2 sm:mt-4 items-center">
                    <div className="mt-2 flex flex-col space-y-1 items-center self-start">
                      <div className="sm:text-lg text-base font-semibold">Users Referred</div>
                      <div className="sm:text-xl text-lg font-bold">{referrals.length}</div>
                    </div>
                    <div className="h-16 w-[1px] bg-white" />
                    <div className="mt-2 flex flex-col space-y-1 items-center self-start">
                      <div className="sm:text-lg font-semibold">XP Earned</div>
                      <div className="sm:text-xl text-lg font-bold">-</div>
                    </div>
                  </div>
                  {
                    referrals.length > 0 &&
                    (
                      <div className="sm:w-9/12 w-11/12 mt-6 border border-zinc-700 max-h-40 overflow-y-scroll mx-auto sm:mx-0">
                        <Table>
                          <Table.Thead>
                            <Table.Tr>
                              <Table.Th>#</Table.Th>
                              <Table.Th>User Profile</Table.Th>
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>{
                            referrals?.map((referral, index) => (
                              <Table.Tr className="hover:bg-zinc-800 cursor-pointer" onClick={() => {
                                navigate(`/profile/${referral?.username}`)
                                setShowLoading(true)
                                setToggleGigs(true)
                              }} key={index}>
                                <Table.Td>{index + 1}</Table.Td>
                                <Table.Td>
                                  <div key={index} className="flex items-center space-x-2">
                                    <img src={referral?.profilePhoto} className="w-8 h-8 rounded-full border-2 border-[#8378BA]" />
                                    <p className="text-semibold">{referral?.username}</p>
                                  </div>

                                </Table.Td>
                              </Table.Tr>
                            ))
                          }
                          </Table.Tbody>
                        </Table>
                      </div>
                    )
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openEditModal && (
        <EditProfileModal
          initialData={profileData}
          onClose={() => {
            setOpenEditModal(false);
            setRefreshPage(true)
          }}
          setLoading={setShowLoading}
        />
      )}
      {openProjectModal && (
        <AddProjectModal
          onClose={() => {
            setOpenProjectModal(false);
          }}
          initialValues={profileData}
          setLoading={setShowLoading}
        />
      )}
      {openExperienceModal && (
        <AddExperienceModal
          onClose={() => {
            setOpenExperienceModal(false);
          }}
          initialValues={profileData}
          setLoading={setShowLoading}
        />
      )}
    </div>
  );
}
