import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./ToggleGigCreate.scss";
const ToggleProfile = ({ id, toggleGigs, setToggleGigs, username }) => {
  const user = useSelector((state) => state.user)
  const handleToggle = () => {
    if (user.username === username) {
      setToggleGigs(toggleGigs === true ? false : true);
    }
  };

  return (
    <div className="flex items-center justify-start w-full mb-5">
      <label htmlFor={`${id}`} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            checked={toggleGigs}
            id={`${id}`}
            name={id}
            className="sr-only"
            onChange={handleToggle}
          />
          <div className={`tar_c bg-gray-800 h-16 ${user.username !== username ? 'w-[128px]' : 'w-60'}`}>
            <p
              className={`tar_text ${!toggleGigs ? "text-white" : "text-gray"
                }`}
            >
              About
            </p>
            <p
              className={`tar_text ${toggleGigs ? "text-white" : "text-gray"
                } ${user.username !== username && 'hidden'}`}
            >
              Referrals
            </p>
          </div>
          <div
            className={`${!toggleGigs
              ? "translate-x-full bg-green-500"
              : "bg-green-500"
              } tar_h`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleProfile;
