import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import stellarlogo from "../../../assets/images/stellarclub.svg";
import flowlogo from "../../../assets/images/flowclub.svg";
import cosmoslogo from "../../../assets/images/cosmosclub.svg";
import onboardinglogo from "../../../assets/images/onboardingclublogo.svg";
import useWindowSize from "../../../utils/windowSize";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import { Menu, rem } from "@mantine/core";
import OBCApi from "../../../api/api";
import getCookieByName from "../../../utils/getCookie";

export default function Header() {
  const { chain } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const size = useWindowSize();
  const [showLoading, setShowLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [profileData, setProfileData] = useState({ name: "R" });

  const user = useSelector((state) => state.user)

  const logo =
    chain === "stellar"
      ? onboardinglogo
      : chain === "flow"
        ? flowlogo
        : chain === "cosmos"
          ? cosmoslogo
          : onboardinglogo;
  useEffect(() => {
    if (getCookieByName("token")) {
      const name = user?.name ?? "R";
      const isAdmin = user?.isAdmin ?? false;
      setProfileData({ name, isAdmin });
      setIsLogged(true);
    }
  }, []);

  const tabs = [
    {
      label: "Home",
      route: `/${chain}`,
      bgColor: "rgba(147, 157, 241, 0.12)",
      color: "#949DF1",
      hasSoon: false,
    },
    {
      label: "Resource Hub",
      route: `/${chain}/hub`,
      bgColor: "rgba(144, 247, 197, 0.12)",
      color: "#90F7C5",
      hasSoon: false,
    },
    {
      label: "Gigs",
      route: `/${chain}/gigs`,
      bgColor: "rgba(249, 160, 110, 0.12)",
      color: "#F9A06E",
      hasSoon: false,
    },
  ];
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = (e) => {
    setDropdownOpen(false);
  };
  // useEffect(() => {
  //   if (isDropdownOpen) {
  //     window.addEventListener("click", closeDropdown);
  //   } else {
  //     window.removeEventListener("click", closeDropdown);
  //   }

  //   return () => {
  //     window.removeEventListener("click", closeDropdown);
  //   };
  // }, [isDropdownOpen]);
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="chainheader"
    >
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="chainheader_inner">
        <div className="chainheader_inner_inner">
          <div className="chainheader_inner_inner_left">
            <img src={logo} alt="onboarding logo" />
          </div>
          <div className="chainheader_inner_inner_right flex md:hidden">
            {/* <div className="chainheader_inner_inner_right_text">Login</div> */}
            {!isLogged ? (
              <Link
                to={`/authenticate`}
                className="chainheader_inner_inner_right_button"
              >
                Authenticate
              </Link>
            ) : (
              <div></div>
            )}
            {!isLogged ? (
              <div></div>
            ) : (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <div
                    className="chainheader_inner_inner_right_profileimagecontainer"
                  >
                    <img className="rounded-full" src={user?.profilePhoto} />
                  </div>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{user?.username}</Menu.Label>
                  <Menu.Item onClick={() => {
                    navigate(`/profile/${user?.username}`)
                  }} >
                    Go To Profile
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    color="red"
                    onClick={async () => {
                      localStorage.clear()
                      const api = new OBCApi("", "")
                      await api.userLogOut()
                      navigate("/")
                    }}
                  >
                    Log Out
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </div>
          <div className="chainheader_inner_inner_center relative">
            <div className="absolute backdrop-blur-xs top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center">
              <p className="sm:text-lg text-base text-white font-semibold px-3 py-1 rounded-full bg-zinc-700 bg-opacity-50">Coming Soon</p>
            </div>
            {tabs.map((tab, index) => (
              <React.Fragment key={index}>
                {tab.hasSoon ? (
                  size.width > 1350 ? (
                    <div
                      className={`chainheader_inner_inner_center_tab cursor-not-allowed ${location.pathname === tab.route ? "active-tab" : ""
                        }`}
                      style={{
                        backgroundColor:
                          location.pathname === tab.route ? tab.bgColor : "",
                        color: location.pathname === tab.route ? tab.color : "",
                      }}
                    >
                      {tab.label}
                      <span>Soon</span>
                    </div>
                  ) : null
                ) : (
                  <Link
                    to={tab.route}
                    className={`chainheader_inner_inner_center_tab ${location.pathname === tab.route ? "active-tab" : ""
                      }`}
                    style={{
                      backgroundColor:
                        location.pathname === tab.route ? tab.bgColor : "",
                      color: location.pathname === tab.route ? tab.color : "",
                    }}
                  >
                    {tab.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="chainheader_inner_inner_right hidden md:flex">
            {/* <div className="chainheader_inner_inner_right_text">Login</div> */}
            {!isLogged ? (
              <Link
                to={`/authenticate`}
                className="chainheader_inner_inner_right_button"
              >
                Authenticate
              </Link>
            ) : (
              <div></div>
            )}

            <div className="dropdown">
              <button
                onClick={toggleDropdown}
                className="chainheader_inner_inner_right_button hidden"
              >
                New +
              </button>
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        setDropdownOpen(false);
                      }, 100);
                    }}
                    to={`/${chain}/register-org`}
                    className="dropdown-link block"
                  >
                    Create Org
                  </Link>
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        setDropdownOpen(false);
                      }, 100);
                    }}
                    to={`/${chain}/gig/create`}
                    className={`dropdown-link ${user.isAdmin ? "block" : "hidden"}`}
                  >
                    Post a Gig
                  </Link>
                  <div className="dropdown-link">
                    Host a Hack <span>Soon</span>
                  </div>
                </div>
              )}
            </div>
            {!isLogged ? (
              <div></div>
            ) : (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <div
                    className="chainheader_inner_inner_right_profileimagecontainer"
                  >
                    <img className="rounded-full" src={user?.profilePhoto} />
                  </div>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{user?.username}</Menu.Label>
                  <Menu.Item onClick={() => {
                    navigate(`/profile/${user?.username}`)
                  }} >
                    Go To Profile
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    color="red"
                    onClick={async () => {
                      localStorage.clear()
                      const api = new OBCApi("", "")
                      await api.userLogOut()
                      navigate("/")
                    }}
                  >
                    Log Out
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
