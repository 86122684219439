import { useEffect } from "react";

export default function Callback() {
  useEffect(() => {
    setTimeout(() => {
      window.close()
    }, 400)
  }, [])
  return (
    null
  );
}
